import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout";

import SEO from "../components/seo";

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO
      description={intl
        .formatMessage({
          id: "event.description",
        })
        .replace(/(<([^>]+)>)/gi, "")}
      title={`Ipone Days - ${intl
        .formatMessage({ id: "common.dates" })
        .replace(/<[^>]*>?/gm, "")}`}
      lang={intl.locale}
    />
    <div className="container" style={{ marginTop: 180 }}>
      <p>
        <h1>Conditions Générales de Vente</h1>
      </p>
      <p>
        <strong>IPONE DAYS - 2022</strong>
      </p>
      <p></p>
      <p>
        <strong>1. OBJET – INFORMATION - DEFINITIONS</strong>
      </p>
      <p>
        Les présentes conditions générales de vente régissent l'utilisation du
        site &nbsp;
        <a href="https://www.iponedays.com/fr/">
          <strong>https://www.iponedays.com/fr/</strong>
        </a>
        et définissent les obligations et les droits de chacune des parties
        concernant la vente des prestations de services qui y sont proposées,
        que le CLIENT soit professionnel ou particulier.
      </p>
      <p>
        IPONE est une SA au capital 100 000,00 € dont le siège social est Chemin
        de la Meunière, 13480 Cabriès - France - Téléphone : &nbsp;
        <a href="https://www.google.com/search?q=adresse+ipone&amp;rlz=1C5CHFA_enFR820FR820&amp;oq=adresse+&amp;aqs=chrome.1.69i57j69i59j35i39j69i59j69i61j69i64l3.5100j0j7&amp;sourceid=chrome&amp;ie=UTF-8">
          04 42 94 05 65
        </a>
        Site web : &nbsp;
        <a href="https://www.ipone.com/">https://www.ipone.com/</a> -TVA
        FR79342438702
      </p>
      <p>
        L'utilisation du site &nbsp;
        <a href="https://www.iponedays.com/fr/">
          <strong>https://www.iponedays.com/fr/</strong>
        </a>
        , la commande et l'utilisation des services en ligne impliquent
        l'acceptation et l’adhésion expresse et sans réserve par le CLIENT ou
        l’utilisateur du site des présentes conditions générales de vente.
        Aucune condition d’achat ou condition particulière ne peut, sauf
        acceptation formelle et écrite d’Iponedays, prévaloir à l’encontre des
        présentes conditions générales de vente.
      </p>
      <p>
        Le terme CLIENT désigne la personne qui réserve un PASS proposé par les
        Iponedays.
      </p>
      <p>
        Le terme PASS désigne le déroulement des différents roulages circuit
        proposés lors des Iponedays.
      </p>
      <p>
        Vous déclarez être majeur (majeur de plus de 18 ans) et jouir de la
        capacité légale nécessaire pour contracter les services proposés par
        Ipone SA.
      </p>
      <p>
        Ipone SA se réserve de pouvoir modifier ses conditions générales de
        vente à tout moment. Dans ce cas, les conditions applicables seront
        celles en vigueur à la date de la commande par le CLIENT.
      </p>
      <p>
        <strong>2. COMMANDES - MODALITES DE RESERVATION</strong>
      </p>
      <p>
        La souscription aux services en ligne proposés par Ipone SA sur le site
        &nbsp;
        <a href="https://www.iponedays.com/fr/">
          <strong>https://www.iponedays.com/fr/</strong>
        </a>
        nécessite que vous complétiez le formulaire de réservation suivant le
        PASS choisi, avec des informations actuelles, complètes et exactes,
        comme le formulaire d'inscription vous y invite.
      </p>
      <p>
        Dès que vous avez cliqué sur le bouton « Valider», votre commande ne
        pourra plus faire l'objet de modification.
      </p>
      <p>
        Une fois le formulaire dûment complété et les conditions générales de
        vente formellement acceptées, votre souscription validée et votre
        paiement en ligne effectué, vous recevrez, dans un délai de 48 heures,
        un courrier électronique de confirmation de votre commande récapitulant
        la teneur de celle-ci.
      </p>
      <p>
        A réception de cette commande, Ipone SA s'assure de la conformité de la
        commande.
      </p>
      <p>
        Ipone SA se réserve la possibilité de ne pas confirmer une commande,
        notamment si celle-ci est anormale ou en cas d'incident de paiement.
      </p>
      <p>A défaut votre réservation ne sera pas prise en compte par Ipone.</p>
      <p>
        <strong>3. DISPONIBILITE DES PASS IPONEDAYS</strong>
      </p>
      <p>
        Les dates des prestations présentées sur le site (à savoir 15-16-17
        octobre 2021) vous sont données à titre définitif et peuvent être
        modifiées uniquement en cas de force majeure.
      </p>
      <p>
        Le CLIENT peut donc considérer cette date comme définitive au moment de
        l’enregistrement de la commande.
      </p>
      <p>
        Les différents horaires définitifs de la prestation seront confirmés
        ultérieurement.
      </p>
      <p>
        <a name="_heading=h.gjdgxs"></a>
        En cas de changement de date, Ipone SA ne pourra en aucun cas être tenue
        pour responsable, mais s'engage à proposer une solution de remplacement
        ou un remboursement sans pénalités.
      </p>
      <p>
        <strong>4. DELAI DE RETRACTATION</strong>
      </p>
      <p>
        Le code de la consommation offre au consommateur un droit de
        rétractation pendant une durée de 14 jours lors d’un achat en ligne lui
        permettant d’obtenir le remboursement ou l’échange du produit.
      </p>
      <p>
        Cependant, conformément à l’article L. 121-21-8 12° du code de la
        consommation, le droit de rétractation ne s’applique pas aux «
        <em>
          prestations de services d’hébergement, autres que d’hébergement
          résidentiel, de services de transport de biens, de locations de
          voitures, de restauration
          <strong>
            ou d’activités de loisirs qui doivent être fournis à une date ou à
            une période déterminée
          </strong>
        </em>
        ».de type Iponedays.
      </p>
      <p>
        <strong>5. CONDITIONS D’ANNULATION ET DE REPORT</strong>
      </p>
      <p>
        <strong>5.1 Pour les différentes formules de PASS</strong>
      </p>
      <p>
        Toute demande de report de prestation prévue dans un PASS, en dehors des
        cas de force majeure prévus sera laissée à l’appréciation de Ipone SA,
        pourra être refusée sans obligation d’en exposer les motifs et conserver
        le règlement des sommes versées, ce en vertu de l’absence de délai de
        rétractation en ce domaine.
      </p>
      <p>
        Toute demande de report de PASS devra être communiquée à l’organisateur,
        par mail ou par écrit, <strong>au plus tard 15 jours</strong> ouvrables
        au moins avant la date prévue pour le stage. En-deçà de ce délai et en
        dehors des cas de force majeure prévus, le PASS sera considéré comme{" "}
        <strong>intégralement dû</strong>, ce même si le CLIENT ne se présente
        pas le jour qui avait été réservé.
      </p>
      <p>
        En cas d’annulation par le client d’un PASS et en dehors des cas de
        force majeure prévus, il ne sera accordé aucun remboursement.
      </p>
      <p>
        L’organisateur se réserve le droit, sans diminution de tarif, de
        reporter une prestation inclue dans un PASS, en cas notamment de force
        majeure ou fortuite, et également, sans que cela soit limitatif, en cas
        de :
      </p>
      <p>● pannes des équipements,</p>
      <p>
        ● de maladie d’un prestataire, et plus généralement tout événement ne
        permettant pas la bonne exécution de la prestation commandée.
      </p>
      <p>
        En aucune manière un défraiement ou un dédommagement ne sera accordé au
        CLIENT.
      </p>
      <p>
        Tout événement extérieur, irréversible, imprévisible ou assimilé à un
        cas de force majeure, ne saurait entraîner la responsabilité de Ipone SA
        à cet égard.
      </p>
      <p>
        De même, Ipone SA se réserve expressément la faculté d’annuler ou de
        reporter la prestation si les conditions de sécurité ne sont pas réunies
        (conditions climatiques ou sanitaires notamment). Ipone SA s'engage à
        proposer une solution de remplacement ou un remboursement sans pénalité
        à l’exclusion de toute autre indemnité quelle qu’elle soit.
      </p>
      <p>
        Ipone SA est seul décisionnaire pour maintenir l'organisation ou non de
        la prestation selon les conditions météorologiques ou sanitaires.
      </p>
      <p>
        Le CLIENT ne pourra prétendre à un quelconque défraiement ou
        dédommagement si la prestation ne se déroule avec des conditions
        météorologiques ne lui convenant pas.
      </p>
      <p>
        <strong>6. INFORMATIONS ADMINISTRATIVES ET SANITAIRES</strong>
      </p>
      <p>
        <strong>
          6.1. INFORMATIONS SUR LA SÉCURITÉ ET LES RISQUES SANITAIRES
        </strong>
      </p>
      <p>
        Il est vivement recommandé au CLIENT, en sus des informations qui lui
        sont communiquées à titre informatif dans les documents contractuels, eu
        égard aux formalités administratives et sanitaires :
      </p>
      <p>
        1. De se renseigner sur la destination de l'évènement auprès des
        autorités compétentes, telles que l’ambassade ou le consulat concernés
        en France, ou encore auprès du ministère des affaires étrangères, ainsi
        que la mairie pour les formalités administratives ;
      </p>
      <p>2. De consulter par ailleurs les sites officiels suivants :</p>
      <p>
        - Pour les formalités administratives, sur le site internet du Ministère
        des Affaires Étrangères en France, la fiche du pays de destination ; et
        notamment les adresses suivantes :
      </p>
      <p>
        http://www.diplomatie.gouv.fr/fr/conseil
        s-aux-voyageurs/conseils-par-pays/
      </p>
      <p>
        http://www.diplomatie.gouv.fr/fr/conseil
        s-aux-voyageurs/infos-pratiques/
      </p>
      <p>
        http://www.diplomatie.gouv.fr/fr/conseil
        s-aux-voyageurs/dernieres-minutes/
      </p>
      <p>
        - Pour les risques sanitaires, les sites internet du Ministère chargé de
        la Santé ainsi que de l’Organisation Mondiale de la Santé (OMS), et la
        rubrique « Santé » de chaque fiche pays du ministère des affaires
        étrangères.
      </p>
      <p>
        <strong>6.2. FORMALITÉS ADMINISTRATIVES ET SANITAIRES</strong>
      </p>
      <p>
        LE CLIENT, DÛMENT INFORMÉ QUANT À L’OBLIGATION DE PROCÉDER AUX DÉMARCHES
        ADMINISTRATIVES LIÉES À L’OBTENTION DE VISAS, DOCUMENTS ET/OU DE METTRE
        À JOUR SES VACCINS, EST SEUL RESPONSABLE DE LA BONNE RÉALISATION DE CES
        DÉMARCHES.
      </p>
      <p>
        SI CES DÉMARCHES SONT DÉTERMINANTES POUR LA PARTICIPATION À LA
        PRESTATION, LE CLIENT NE POURRA SE RETOURNER CONTRE IPONE SA, SI
        CELUI-CI NE PEUT PARTICIPER À LA PRESTATION DU FAIT DU NON-RESPECT DE
        CES DÉMARCHES.
      </p>
      <p>
        Il lui appartient de vérifier et de se tenir informé des délais
        d’obtention des visas, documents et/ou des vaccins qui sont applicables
        dans son cas particulier, en sus des informations qui lui sont fournies
        à cet égard par Ipone SA à titre indicatif.
      </p>
      <p>
        S’agissant plus particulièrement des formalités administratives, il
        revient au CLIENT participant de vérifier qu’il dispose d’un document
        d’identité (passeport, carte nationale d’identité dans le cas où
        celle-ci est suffisante) en cours de validité, ainsi que de tout autre
        document administratif spécifique qui peut être requis (visa, ESTA,
        livret de famille, autorisation de sortie du territoire, etc.).
      </p>
      <p>
        Il appartient au CLIENT ressortissant de nationalité française
        d'accomplir les formalités en vue de l'obtention du ou des visas,
        documents ou autres autorisations auprès des autorités compétentes et à
        ses frais.
      </p>
      <p>
        Les personnes de nationalité étrangère doivent s'informer et se
        renseigner avant d'entreprendre une inscription sur les formalités
        administratives et sanitaires requises notamment, auprès des ambassades
        ou consulats compétents.
      </p>
      <p>
        Par ailleurs, il appartient au CLIENT de vérifier pour son cas
        particulier, qu’il dispose de tous les documents personnels nécessaires
        afin de conduire légalement un Véhicule dans la/les destination(s) de la
        Prestation.
      </p>
      <p>
        En outre, certaines compagnies de transport, telles des compagnies
        aériennes peuvent mettre en place des formalités spécifiques à
        respecter. Il appartient au CLIENT de vérifier ces formalités lors de la
        réservation des titres de transport.
      </p>
      <p>
        Le CLIENT est informé que l’ensemble des informations susvisées sont
        susceptibles d’évoluer à tout moment, jusqu’au jour du départ, notamment
        en cas de risque sanitaire (tel qu’une épidémie) ou d’intempéries.
      </p>
      <p>
        Il appartient à chaque CLIENT de vérifier auprès des autorités
        compétentes que les informations dont il dispose sont toujours valables
        et ainsi de s’informer de la mise à jour éventuelle des formalités
        administratives et/ou sanitaires qu’il doit accomplir, jusqu’à la veille
        de son départ.
      </p>
      <p>
        De plus, il est de la seule responsabilité du CLIENT de suivre
        l’intégralité des recommandations émises notamment en cas de risque
        sanitaire, par les autorités compétentes en France et sur place par les
        organismes reconnus dans le milieu de la santé (tel que l’OMS), ou
        encore par Ipone SA et/ou autres prestataires, et de s’en tenir informé
        au jour le jour, ce à quoi il s’engage expressément dans le cadre des
        présentes.
      </p>
      <p>
        LE CLIENT S’ENGAGE A SE TENIR INFORMÉ ET À RESPECTER L’ENSEMBLE DES
        INFORMATIONS ET RECOMMANDATIONS SUSVISÉES DANS LE CADRE DES PRÉSENTES.
      </p>
      <p>
        <strong>7. PRIX DE VENTE DES PRESTATIONS - PAIEMENT</strong>
      </p>
      <p>
        Les prix des prestations sont indiqués nets et en euros. Ipone SA se
        réserve le droit de modifier le prix des PASS à tout moment. Les
        prestations seront facturées sur la base des tarifs en vigueur et
        affichés sur le site au moment de l'enregistrement et du paiement de la
        commande. Le prix de la formation ou du stage est payable lors de la
        commande, de la façon suivante : - par cartes bancaires : Visa,
        MasterCard, CB sur le site internet.
      </p>
      <p>
        <strong>8. RESPECT DES INSTRUCTIONS, OBLIGATIONS DU CLIENT</strong>
      </p>
      <p>
        Dans le cadre des prestations de votre PASS, vous êtes tenu de respecter
        scrupuleusement les instructions délivrées par Ipone SA en ce qui
        concerne notamment la sécurité et le déroulement du PASS.
      </p>
      <p>
        Vous déclarez disposer d’une assurance responsabilité civile. Vous devez
        vous munir des équipements exigés, dont vous déclarez avoir eu
        expressément connaissance, par Ipone SA. A défaut, Ipone SA se réserve
        expressément la faculté de ne pas vous accepter lors du roulage circuit.
        Dans ce cas, aucune somme ne vous sera remboursée.
      </p>
      <p>
        Ipone SA se réserve le droit d'exclure à tout moment toute personne dont
        le comportement s’apparenterait à de l’incivilité, ou dont le
        comportement pourrait mettre en danger les autres participants, les
        membres de l'organisation, toute personne ou tout bien se trouvant sur
        le domaine public, ou lui-même. Dans ce cas, vous ne pourrez pas
        prétendre au remboursement du PASS, ni à un quelconque défraiement ou
        dédommagement. En cas de non respect des consignes données par les
        organisateurs, la responsabilité de Ipone SA et des organisateurs ne
        pourra en aucun cas être engagée.
      </p>
      <p>
        Les participants aux stages de conduite doivent être majeurs et en
        possession d’un permis de conduire en cours de validité ou d’une licence
        sauf dans le cadre d’un baptême sur piste. Pour des raisons de sécurité,
        en cas d’état physique ne permettant pas la participation au stage
        (médicaments, alcool, drogues, grossesse quel qu’en soit le stade…)
        Ipone SA se réserve le droit d’annuler la participation au stage. Dans
        ce cas, les personnes concernées ne sauraient prétendre au remboursement
        de leurs frais d’inscription ni à un quelconque défraiement ou
        dédommagement.
      </p>
      <p>
        <strong>9. RESPONSABILITE DE IPONE SA</strong>
      </p>
      <p>
        Dans le cadre de l’exécution du PASS vous vous engagez à pratiquer sur
        le circuit à vos risques et périls, sous votre propre responsabilité.
      </p>
      <p>
        <strong>10. SECURISATION DES PAIEMENTS EN LIGNE</strong>
      </p>
      <p>
        Le site est équipé d'un système de paiement immédiat par carte bancaire
        sécurisé SSL qui permet de crypter, avec une clé, le numéro de carte
        bancaire du CLIENT.
      </p>
      <p>
        <strong>11. INFORMATIONS NOMINATIVES</strong>
      </p>
      <p>
        Vous vous engagez à donner des informations exactes, lors de votre
        réservation et à informer Ipone SA sans délai de toute modification de
        ces informations telles que vous les avez décrites lors de votre
        réservation. Certaines informations demandées dans les bons de commande
        et les formulaires ont un caractère obligatoire et sont signalées par un
        astérisque rouge (*) ; si vous choisissez de ne pas les communiquer à
        Ipone SA, votre demande ne pourra être traitée. Le CLIENT est
        expressément informé qu’Ipone SA sera en droit de communiquer tout ou
        partie des données personnelles du client sur injonction des autorités
        judiciaires.
      </p>
      <p>
        Conformément à la loi informatique et libertés du 6 janvier 1978, vous
        disposez d'un droit d'accès, de rectification, et d'opposition aux
        données personnelles vous concernant.
      </p>
      <p>
        Sur demande, ces données peuvent vous être communiquées et, en cas
        d’erreur ou de modification, être rectifiées. Vous pouvez également vous
        opposer à ce qu’elles soient communiquées à des tiers en adressant un
        courrier à Ipone SA.
      </p>
      <p>
        <strong>12. DROIT À L’IMAGE</strong>
      </p>
      <p>
        Les participants aux stages reconnaissent être informés que des photos
        et des vidéos peuvent être réalisées par Ipone SA lors des journées des
        roulages circuits. Ces photos et vidéos pourront être exploitées
        librement par Ipone SA à des fins publicitaires, promotionnelles ou
        commerciales, et ce sans aucune contrepartie ni réclamation possible
        pour les participants eu égard à leur droit à l’image.
      </p>
      <p>
        <strong>13. NULLITE</strong>
      </p>
      <p>
        Si l'une quelconque des stipulations du présent contrat se révélait
        nulle, illégale ou jugée inapplicable pour quelque raison que ce soit,
        la dite stipulation ne devra recevoir aucun effet et être considérée
        comme ne faisant pas partie du contrat, ce sans affecter ou altérer la
        validité, la légalité ou l’applicabilité des autres clauses.
      </p>
      <p>
        <strong>14. LITIGES</strong>
      </p>
      <p>
        Toutes les clauses figurant dans les présentes conditions générales de
        vente, ainsi que toutes les prestations qui y sont visées, seront
        soumises au droit français.
      </p>
    </div>
  </Layout>
);

export default injectIntl(IndexPage);
